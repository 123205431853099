<template>
  <main v-if="ticket">
    <div>
      <trac-back-button>
        <div class="mt-x">Tickets</div>
      </trac-back-button>
      <h1 class="mt-3 font-medium">Ticket Details</h1>
    </div>
    <div class="grid w-full grid-cols-6 col-gap-6 mt-8">
      <div class="white-shadow bg-white rounded-lg p-4 flex">
        <div class="flex-column w-4/12">
          <img src="../../assets/svg/receipt.svg" />
        </div>
        <div class="flex-column">
          <div class="text-xs text-primaryGray">
            Date <br />
            Created
          </div>
          <div class="text-sm font-semibold uppercase mt-3">
            {{ moment(ticket.created_at).format("Do-MMM-YYYY") }}
          </div>
        </div>
      </div>

      <div class="white-shadow bg-white rounded-lg p-4 flex">
        <div class="flex-column w-4/12">
          <img src="../../assets/svg/trans_type.svg" />
        </div>
        <div class="flex-column">
          <div class="text-xs text-primaryGray">
            Ticket <br />
            Name
          </div>
          <div class="text-sm font-semibold mt-3 uppercase">
            {{ ticket.ticket_name }}
          </div>
        </div>
      </div>

      <div class="white-shadow bg-white rounded-lg p-4 flex">
        <div class="flex-column w-4/12">
          <img src="../../assets/svg/trans_type.svg" />
        </div>
        <div class="flex-column">
          <div class="text-xs text-primaryGray">
            Sale <br />
            Agent
          </div>
          <div class="text-sm font-semibold mt-3 uppercase">
            {{ ticket.sale_agent }}
          </div>
        </div>
      </div>
      <div class="white-shadow bg-white rounded-lg p-4 flex">
        <div class="flex-column w-4/12">
          <img src="../../assets/svg/trans_type.svg" />
        </div>
        <div class="flex-column">
          <div class="text-xs text-primaryGray">
            Customer <br />
            Name
          </div>
          <div class="text-sm font-semibold mt-3 uppercase">
            {{ ticket.customer_name ? ticket.customer_name : "N/A" }}
          </div>
        </div>
      </div>

      <div class="white-shadow bg-white rounded-lg p-4 flex">
        <div class="flex-column w-4/12">
          <img src="../../assets/svg/amt_received.svg" />
        </div>
        <div class="flex-column">
          <div class="text-xs text-primaryGray">
            No of <br />
            Items
          </div>
          <div class="text-sm font-semibold mt-3">
            {{ ticket.items.length }}
          </div>
        </div>
      </div>
      <div
        class="white-shadow relative bg-white rounded-lg p-4 flex flex-col justify-between"
      >
        <div class="flex">
          <div class="flex-column w-4/12">
            <img src="../../assets/svg/pay_date.svg" />
          </div>
          <div class="flex-column">
            <div class="text-xs text-primaryGray">
              Total <br />
              Price
            </div>
            <div class="text-sm font-semibold mt-3">
              {{ ticket.total_price | formatPrice }}
            </div>
          </div>
        </div>

        <div
          class="text-center font-medium cursor-pointer text-primaryGray mt-5 text-xs"
        >
          <!-- {{ moment(history.created_at).format("hh:mm A") }} -->
        </div>
      </div>
    </div>

    <div class="flex flex-row gap-5 mt-5">
      <div class="flex-column w-full">
        <div class="bg-white py-6 px-8 h-full rounded-lg big-shadow">
          <div class="font-medium text-sm mb-6">Order Details</div>
          <perfect-scrollbar>
            <div class="max-heights">
              <div
                class="flex flex-row py-2 items-center border-t justify-between"
                v-for="item in ticket.items"
                :key="item._id"
              >
                <div class="flex items-center">
                  <div class="h-12 w-12">
                    <img
                      class="rounded w-full h-full object-cover"
                      v-if="!!item.product_image"
                      :src="item.product_image"
                      alt=""
                    />
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      style="enable-background: new 0 0 512 512"
                      xml:space="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M446.575,0H65.425C29.349,0,0,29.35,0,65.426v381.149C0,482.65,29.349,512,65.425,512h381.15    C482.651,512,512,482.65,512,446.574V65.426C512,29.35,482.651,0,446.575,0z M481.842,446.575    c0,19.447-15.821,35.267-35.267,35.267H65.425c-19.447,0-35.268-15.821-35.268-35.267v-55.007l99.255-84.451    c3.622-3.082,8.906-3.111,12.562-0.075l62.174,51.628c5.995,4.977,14.795,4.569,20.304-0.946L372.181,209.77    c2.67-2.675,5.783-2.935,7.408-2.852c1.62,0.083,4.695,0.661,7.078,3.596l95.176,117.19V446.575z M481.842,279.865l-71.766-88.366    c-7.117-8.764-17.666-14.122-28.942-14.701c-11.268-0.57-22.317,3.672-30.294,11.662L212.832,326.681l-51.59-42.839    c-14.959-12.422-36.563-12.293-51.373,0.308l-79.712,67.822V65.426c0-19.447,15.821-35.268,35.268-35.268h381.15    c19.447,0,35.267,15.821,35.267,35.268V279.865z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M161.174,62.995c-40.095,0-72.713,32.62-72.713,72.713c0,40.094,32.619,72.713,72.713,72.713s72.713-32.619,72.713-72.713    S201.269,62.995,161.174,62.995z M161.174,178.264c-23.466,0-42.556-19.091-42.556-42.556c0-23.466,19.09-42.556,42.556-42.556    c23.466,0,42.556,19.091,42.556,42.556S184.64,178.264,161.174,178.264z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </div>
                  <div>
                    <span class="text-xs font-medium tracking-wide pl-2">{{
                      item.name
                    }}</span>
                  </div>
                </div>
                <div>
                  <span class="text-sm">{{ item.price | formatPrice }}</span>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <!-- <div class="flex flex-row justify-end mt-4">
            <span class="font-medium text-xl"
              >Total: {{ total | formatPrice }}</span
            >
          </div> -->
        </div>
      </div>
      <!-- <div class="flex-column w-full">
        <div class="bg-white py-6 px-8 p-6 h-full rounded-lg white-shadow">
          <span class="font-medium text-xl">Store Information</span>
          <div class="mt-8">
            <div
              style="grid-template-columns: 1fr 3fr"
              class="grid items-center"
            >
              <span class="text-xs text-primaryGray">Sold by:</span>
              <span class="text-xs text-primaryBlue font-medium underline">
                {{
                  history.sale_agent ? history.sale_agent : "not available"
                }}</span
              >
            </div>
            <div
              style="grid-template-columns: 1fr 3fr"
              class="grid items-center mt-2"
            >
              <span class="text-xs text-primaryGray">Store Name:</span>
              <span class="text-xs text-primaryBlue font-medium underline">{{
                history.store_name
              }}</span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </main>
</template>

<script>
// import ResendReceipt from "./ResendReceipt";
// import SelectCustomer from "./SelectCustomer";
import moment from "moment";
import { mapGetters } from "vuex";
import { GET_USER_BUSINESS_DATA } from "../../browser-db-config/localStorage";

export default {
  name: "SalesDetails",
  //   components: { SelectCustomer, ResendReceipt },
  data() {
    return {
      resendReceiptModal: false,
      selectCustomerModal: false,
      moment,
      ticket: null,
    };
  },
  computed: {
    ...mapGetters(["GET_ALL_SALES_HISTORY"]),
    history() {
      return this.GET_ALL_SALES_HISTORY.data.salesHistory.find((x) => {
        return x._id == this.$route.params.id;
      });
    },
    total() {
      let k = this.history.items.reduce((a, { price }) => a + price, 0);
      return k;
    },
  },
  mounted() {
    this.ticket = this.$store.state.pos.tickets;
  },
};
</script>

<style scoped>
.white-shadow {
  box-shadow: 0px 0px 20px rgba(22, 120, 176, 0.12);
}
.max-heights {
  max-height: 15rem;
  overflow-y: auto;
}
</style>
